export default [
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import(/* webpackChunkName: "iframe" */ '../views/index/iframe.vue')
  },
  {
    path: '/gwql',
    name: 'gwql',
    component: () => import(/* webpackChunkName: "gwql" */ '../views/index/gwql.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/index/news.vue')
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import(/* webpackChunkName: "shareDetail" */ '../views/share/shareDetail.vue')
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "cash" */ '../views/mine/cash.vue')
  },
  {
    path: '/bindBank',
    name: 'bindBank',
    component: () => import(/* webpackChunkName: "bindBank" */ '../views/mine/bindBank.vue')
  },
  {
    path: '/real',
    name: 'real',
    component: () => import(/* webpackChunkName: "real" */ '../views/mine/real.vue')
  },
  {
    path: '/cashDetail',
    name: 'cashDetail',
    component: () => import(/* webpackChunkName: "cashDetail" */ '../views/mine/cashDetail.vue')
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import(/* webpackChunkName: "moneyDetail" */ '../views/mine/moneyDetail.vue')
  },
  {
    path: '/appxz',
    name: 'appxz',
    component: () => import(/* webpackChunkName: "appxz" */ '../views/mine/appxz.vue')
  },
  
  {
    path: '/sell',
    name: 'sell',
    component: () => import(/* webpackChunkName: "sell" */ '../views/mine/sell.vue')
  },
]
<template>
  <div class="page">
    <router-view />
    <div class="footer flex-row justify-around align-center" style="z-index: 999;">
      <div class="flex-column align-center" v-for="item in navList" :key="item.id"
        @click="changeNav(item.id, item.url)">
        <img :src="navId == item.id ? item.iconSelect : item.icon" alt="" style="width: 0.68rem;height: auto" />
        <p :class="(navId == item.id ? 'color-red' : 'color-gay') + ' pt-1'" style="font-size: 0.20rem;">
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      navId: 0,
      navList: [
        {
          id: 0,
          title: "首页",
          icon: require("../assets/img/footer/sy.png"),
          iconSelect: require("../assets/img/footer/sy-1.png"),
          url: "/",
        },
        {
          id: 1,
          title: "邀请",
          icon: require("../assets/img/footer/yq.png"),
          iconSelect: require("../assets/img/footer/yq-1.png"),
          url: "/yq",
        },
        {
          id: 2,
          title: "大国钱包",
          icon: require("../assets/img/footer/jzqb.png"),
          iconSelect: require("../assets/img/footer/jzqb-1.png"),
          url: "/qb",
        },
        {
          id: 3,
          title: "大国股权",
          icon: require("../assets/img/footer/jzgq.png"),
          iconSelect: require("../assets/img/footer/jzgq-1.png"),
          url: "/gq",
        },
        {
          id: 4,
          title: "我的",
          icon: require("../assets/img/footer/wd.png"),
          iconSelect: require("../assets/img/footer/wd-1.png"),
          url: "/mine",
        }
      ],
    };
  },
  created() {
    this.check();
  },
  provide() {
    return {
      check: this.check,
    };
  },
  methods: {
    changeNav(id, url) {
      if (this.navId != id) {
        this.navId = id;
        this.$router.push({ path: url });

      }
    },
    check() {
      for (var i = 0; i < this.navList.length; i++) {
        if (this.navList[i].url == this.$route.path) {
          this.navId = this.navList[i].id;
        }
      }
    },
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.44rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.18rem 0 rgba(0, 0, 0, 0.25);
}
</style>